<template lang="pug">
Dialog.dialog-imprimir(:modal="true" header= "Imprimir Relatório" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    .ta-center(v-if='waitSubmit' )
        ProgressSpinner.waiting-spinner(strokeWidth='6')
    form(v-else header="Realizar Sangria")
        .p-grid.p-fluid.p-align-top.p-justify-left
            .p-col-12.ta-center
                label.form-label Escolha o modelo do relátorio
            .p-col-12.ta-center.my-1(style="display: flex")
                Button.mr-2(v-if="ctx === 'OPE'" label='Térmica' type="button" @click='imprimir(false, true)')
                Button.mr-2(label='Sintético' type="button" @click='imprimir(false, false)')
                Button.mr-2(label='Analítico' type="button" @click='imprimir(true, false)')
                Button(v-if="ctx !== 'OPE'" label='Completo' type="button" @click='imprimirCompleto()')
            //- .p-col-12.ta-center
            //-     label.form-label Escolha o modelo do relátorio
            //- .p-col-3.ta-right(v-if="ctx === 'OPE'")
            //-     Button(label='Térmica' type="button" @click='imprimir(false, true)')
            //- .p-col-3.ta-right
            //-     Button(label='Sintético' type="button" @click='imprimir(false, false)')
            //- .p-col-3.ta-right
            //-     Button(label='Analítico' type="button" @click='imprimir(true, false)')
            //- .p-col-3.ta-right(v-if="ctx !== 'OPE'")
            //-     Button(label='Completo' type="button" @click='imprimirCompleto()')
</template>
<style lang="scss">
.waiting-spinner {
    width: 38px;
    height: auto;
}
</style>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import { Caixas, Relatorio } from "@/middleware";
    import { saveAs } from 'file-saver'
    import moment from 'moment'

    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, ProgressSpinner, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
        },
        props: [
            'display', 'tipo_caixa', 'id', 'ctx'
        ],
        computed: {
            show: {
                get() {
                    // if (!this.display) this.resetModel()
                    return this.display;
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        created () {

        },
        data () {
            return {
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                waitSubmit: false,
                submitted: false,
                dialogConfirm: false,
            }
        },
        methods: {
            resetModel() {
                this.model.nr_valor = null
                this.model.tipoOperacao = null
                this.waiting = true,
                this.waitingForm = false
                this.submitted = false
            },
            async enviarImpressoraTermica(data) {
                const blob = new Blob([data], { type: "text/html; charset=utf-8" })
                let formData = new FormData()
                formData.append("file", blob)

                await fetch("http://localhost:9990/imprimir", { method: "POST", body: formData })
                    .catch(() => this.$toast.error("Sem conexão com impressora térmica.", { duration: 3000 }))
            },
            imprimir (ie_analitico, termica) {
                this.submitted = this.waitSubmit = true

                // const func = this.tipo_caixa === "SUP" 
                //         ? this.ctx !== "OPE" 
                //             ? 'findFechamentoSupervisao' 
                //             : 'findFechamentoOperacional'
                //         : 'findFechamentoFundoFixo'
                const func = this.tipo_caixa === "SUP" 
                        ? this.ctx !== "OPE" 
                            ? 'findFechamentoSupervisao' 
                            : 'findFechamentoOperacional'
                        : this.tipo_caixa === "FUN"
                            ? 'findFechamentoFundoFixo' 
                            : 'findFechamentoOperacional'

                Caixas[func](this.id, { export: termica ? 'html' : 'pdf', ie_analitico: ie_analitico }).then(response => {
                    this.submitted = false
                    if (response.status == 200) {
                        let blob = new Blob([response.data], { type: 'application/pdf; charset=utf-8' })
                        saveAs(blob, `relatorio-caixa-${ this.id }-${ moment().format('YYYYMMDD_HHmmss') }.pdf`)
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.submitted = this.waitSubmit = false
                    this.$emit('close')
                })
            },
            imprimirCompleto() {
                this.submitted = this.waitSubmit = true

                Relatorio.emitirRelatorio({ 
                    "cd_relatorio": 42, 
                    "nr_relatorio": 42, 
                    "cd_saldo": this.id,
                    "export": 'pdf',
                    "ie_analitico": 'true',
                    "ie_completo": 'true'
                }).then(this.$router.push('/relatorio/listar'))
            }
        },
    }
</script>

<style lang="scss">

.dialog-imprimir {
    width: 600px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>
