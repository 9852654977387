<template lang="pug">
Dialog.dialog-sangria(:modal="true" header= "Depositar Banco" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    ConfirmDialog(:display='dialogConfirm' :text="'Você está prestes a depositar um valor do caixa supervisão para o banco. Deseja prosseguir com essa ação?'" @close='dialogConfirm=false' @confirmed='handleSubmit()')
    form(header="Depositar Banco" )
        .p-grid.p-fluid.p-align-top.p-justify-center
            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                label.form-label Valor:
                .p-inputgroup
                    span.p-inputgroup-addon {{'R$'}}
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        v-model='$v.model.nr_valor.$model' locale='pt-BR' :auto-decimal-mode='true'
                        :value-range='{ min: 0, max: this.valorMax }')
                    Button(type='button' :label="`Máximo: ${this.formatPrice(this.valorMax)}`" style='width: 60%' @click='setValorMax()')
                .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                    .form-message--error(v-if="!$v.model.nr_valor.required") <b>Valor</b> é obrigatório.
                    .form-message--error(v-if="!$v.model.nr_valor.minValue") <b>O valor</b> precisa ser maior que <b> 0 </b>.
                    .form-message--error(v-if="!$v.model.nr_valor.maxValue") <b>O valor</b> precisa ser menor ou igual que <b> {{ this.formatPrice(this.valorMax) }} </b>.

            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_destino.$error }")
                label.form-label Destino:
                Dropdown(v-model='$v.model.cd_destino.$model' :options='options.destino' :disabled='!model.ie_tipo || model.id'
                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='true')
                .feedback--errors(v-if='submitted && $v.model.cd_destino.$error')
                    .form-message--error(v-if="!$v.model.cd_destino.required") <b>Destino</b> é obrigatório.

            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.cd_plano_contas.$error }")
                label.form-label Conta Financeira:
                Dropdown(v-model='$v.model.cd_plano_contas.$model' :options='options.planoContas' :disabled='!model.ie_tipo || model.id'
                    dataKey='value' optionLabel='label' optionValue='value' placeholder='Selecione' :filter='true')
                .feedback--errors(v-if='submitted && $v.model.cd_plano_contas.$error')
                    .form-message--error(v-if="!$v.model.cd_plano_contas.required") <b>Conta financeira</b> é obrigatório.

            .p-col-12
                label.form-label Observação:
                Textarea(v-model='model.ds_observacao' :autoResize="true" rows="5")

            .p-col-12.ta-right
                    Button(label='Salvar' type="button" @click='dialogConfirm=true')
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import { Transferencias, ContasBancarias, PlanoContas } from "@/middleware"
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
    import Textarea from 'primevue/textarea';
    import moment from "moment"

    import { required, minValue, maxValue } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
            ConfirmDialog, Textarea,
        },
        props: [
            'display',
            'dt_transferencia',
            'valorMax',
            'cd_caixa',
        ],
        computed: {
            show: {
                get() { 
                    if (!this.display) this.resetModel()
                    else {
                        this.getDestino()
                        this.getContaFinanceira()
                    }
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        created () {

        },
        data () {
            return {
                model: {
                    ie_tipo: 'CB',
                    nr_valor: null,
                    cd_destino: null,
                    cd_plano_contas: null,
                    ds_observacao: '',
                },
                options: {
                    destino: [],
                    planoContas: [],
                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                submitted: false,
                dialogConfirm: false,
            }
        },
        validations () {
            let v = {
                model: {
                    nr_valor: {
                        required,
                        minValue: minValue(0.01),
                        maxValue: maxValue(this.valorMax)
                    },
                    cd_destino: { required },
                    cd_plano_contas: { required },
                }
            }
            
            return v
        },
        methods: {
            resetModel() {
                this.model.cd_destino = null
                this.model.ds_observacao = ''
                this.model.cd_plano_contas = null
                this.model.nr_valor = null
                this.waiting = true,
                this.waitingForm = false,
                this.submitted = false,
                this.dialogConfirm = false
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            setValorMax () {
                this.model.nr_valor = this.valorMax
            },
            getDestino() {
                ContasBancarias.findAll().then(response => {
                    if (([200, 201]).includes(response.status)) {
                        response.data.forEach(i => {
                            this.options.destino.push({
                                value: i.id,
                                text: i.ds_codigo.concat( "-", i.nm_banco, "-", i.nr_agencia, "-", i.nr_conta ),
                            })
                        })
                    } else {
                        this.$toast.error("Houve um problema ao carregar destino.", { duration: 3000 });
                    }
                })
            },
            getContaFinanceira () {
                PlanoContas.findAll({ie_tipo: 'A'}).then(response => {
                    this.options.planoContas = [{value: null, label: 'Selecione'}]
                    if (([200, 201]).includes(response.status)) {
                        response.data.forEach(i => {
                            this.options.planoContas.push({
                                value: i.id,
                                label: i.ds_descricao,
                            })
                        })
                    } else {
                        this.$toast.error("Houve um problema ao carregar as contas financeiras.", { duration: 3000 });
                    }
                })
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model, {cd_origem: this.cd_caixa, dt_transferencia: moment(this.dt_transferencia).utcOffset('-03:00').format('YYYY-MM-DD')})

                Transferencias.save(dataSend).then(response => {
                    this.submitted = false;
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success(
                            "Deposito REALIZADO com sucesso."
                        );
                        this.$emit('close')
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                });
            }
        },
    }
</script>

<style lang="scss">

.dialog-sangria {
    width: 600px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>
