<template lang="pug">
Dialog.dialog-sangria(:modal="true" header= "Realizar Sangria" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    ConfirmDialog(:display='dialogConfirm' :text="'Você está prestes a completar uma sangria. Essa ação não pode ser desfeita. Deseja prosseguir?'" @close='dialogConfirm=false' @confirmed='handleSubmit()')
    form(header="Realizar Sangria")
        .p-grid.p-fluid.p-align-top.p-justify-center
            .p-col-12.p-md-8(:class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                label.form-label Valor Sangria:
                .p-inputgroup
                    span.p-inputgroup-addon {{'R$'}}
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        v-model='$v.model.nr_valor.$model' locale='pt-BR' :auto-decimal-mode='true'
                        :value-range='{ min: 0, max: 9999999 }')
                    Button(type='button' :label="`Máximo: ${this.formatPrice(this.valorMax)}`" style='width: 100%' @click='setValorMax()')
                .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                    .form-message--error(v-if="!$v.model.nr_valor.required") <b>Valor Sangria</b> é obrigatório.
                    .form-message--error(v-if="!$v.model.nr_valor.minValue") <b>O valor da sangria</b> precisa ser maior que <b> 0 </b>.
                    .form-message--error(v-if="!$v.model.nr_valor.maxValue") <b>O valor da sangria</b> precisa ser menor ou igual que <b> {{ this.valorMax }} </b>.
            //- .p-col-12.p-md-4(:class="{ 'form-group--error': submitted && $v.model.cd_comprovante.$error }")
            //-     label.form-label Código do Comprovante:
            //-     InputText(v-model='$v.model.cd_comprovante.$model' type='number' @input='limitarCaracteres()')
            //-     .feedback--errors(v-if='submitted && $v.model.cd_comprovante.$error')
            //-         .form-message--error(v-if="!$v.model.cd_comprovante.required") <b>Código do Comprovante</b> é obrigatório.
            .p-col-12.p-md-4
                label.form-label Código do Comprovante:
                InputText(v-model='model.cd_comprovante' type='number' @input='limitarCaracteres()')
            .p-col-12.ta-right
                Button(label='Salvar' type="button" @click='dialogConfirm = true')
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputNumber from 'primevue/inputnumber';
    import InputText from 'primevue/inputtext';
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import { Caixas } from "@/middleware";
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'

    import { required, minValue, maxValue } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputNumber, Button, Password, Dialog, InputText, SelectButton, Dropdown, Calendar, DataTable, Column, ConfirmDialog
        },
        props: [
            'display',
            'valorMax',
        ],
        computed: {
            show: {
                get() { 
                    if (!this.display) this.resetModel()
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        created () {

        },
        data () {
            return {
                model: {
                    nr_valor: null,
                    cd_comprovante: null,
                },
                options: {

                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                submitted: false,
                dialogConfirm: false,
            }
        },
        validations () {
            let v = {
                model: {
                    nr_valor: {
                        required,
                        minValue: minValue(0.01),
                        maxValue: maxValue(this.valorMax)
                    },
                    // cd_comprovante: { required },
                }
            }
            
            return v
        },
        methods: {
            resetModel() {
                this.model.nr_valor = null
                this.model.cd_comprovante = null
                this.waiting = true,
                this.waitingForm = false
                this.submitted = false
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            setValorMax () {
                this.model.nr_valor = this.valorMax
            },
            limitarCaracteres() {
                const maxLength = 10;
                if (this.model.cd_comprovante.length > maxLength) {
                    this.model.cd_comprovante = this.model.cd_comprovante.slice(0, maxLength);
                }
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                if(this.model.cd_comprovante == null) delete this.model.cd_comprovante

                Caixas.realizarSangria(this.model).then(response => {
                    this.submitted = false;
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success(
                            "Sangria REALIZADA com sucesso."
                        );
                        this.$emit('close')
                        location.reload()
                    } 
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                });
            }
        },
    }
</script>

<style lang="scss">

.dialog-sangria {
    width: 720px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>
