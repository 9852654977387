<template lang="pug">
Dialog.dialog-sangria(:modal="true" header= "Depósito/Saque Fundo Fixo" :visible.sync="show" :contentStyle="{overflowY: 'auto !important'}")
    form(header="Depósito/Saque Fundo Fixo" @submit.prevent='handleSubmit()')
        .p-grid.p-fluid.p-align-top.p-justify-center
            .p-col-12
                label.form-label Tipo de Operação:
                SelectButton(v-model='$v.model.tipoOperacao.$model' :options='options.tipoOperacaoOption' optionValue='value' dataKey='value' optionLabel='label' @input='model.nr_valor = null')
                .feedback--errors(v-if='submitted && $v.model.tipoOperacao.$error')
                    .form-message--error(v-if="!$v.model.tipoOperacao.required") <b>Tipo de operação</b> é obrigatório.
            .p-col-12(v-if='model.tipoOperacao == "D"' :class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                label.form-label Valor:
                .p-inputgroup
                    span.p-inputgroup-addon {{'R$'}}
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        v-model='$v.model.nr_valor.$model' locale='pt-BR' :auto-decimal-mode='true' :disabled='valorMaxDeposito <= 0'
                        :value-range='{ min: 0, max: valorMaxDeposito }')
                    Button(v-if='valorMaxDeposito > 0' type='button' :label="`Máximo: ${this.formatPrice(valorMaxDeposito)}`" style='width: 60%' @click='setValorMax()')
                .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                    .form-message--error(v-if="!$v.model.nr_valor.required") <b>Valor</b> é obrigatório.
                    .form-message--error(v-if="!$v.model.nr_valor.minValue") <b>O valor</b> precisa ser maior que <b> 0 </b>.
                    .form-message--error(v-if="!$v.model.nr_valor.maxValue") <b>O valor</b> precisa ser menor ou igual que <b> {{`${this.formatPrice(valorMaxDeposito)}`}} </b>.
            .p-col-12(v-if='model.tipoOperacao == "S"' :class="{ 'form-group--error': submitted && $v.model.nr_valor.$error }")
                label.form-label Valor:
                .p-inputgroup
                    span.p-inputgroup-addon {{'R$'}}
                    currency-input.p-inputnumber-input.p-inputtext.p-component.p-filled.ta-right(
                        v-model='$v.model.nr_valor.$model' locale='pt-BR' :auto-decimal-mode='true' :disabled='valorMaxSaque <= 0'
                        :value-range='{ min: 0, max: valorMaxSaque }')
                    Button(v-if='this.valorMaxSaque > 0' type='button' :label="`Máximo: ${this.formatPrice(valorMaxSaque)}`" style='width: 60%' @click='setValorMax()')
                .feedback--errors(v-if='submitted && $v.model.nr_valor.$error')
                    .form-message--error(v-if="!$v.model.nr_valor.required") <b>Valor</b> é obrigatório.
                    .form-message--error(v-if="!$v.model.nr_valor.minValue") <b>O valor</b> precisa ser maior que <b> 0 </b>.
                    .form-message--error(v-if="!$v.model.nr_valor.maxValue") <b>O valor</b> precisa ser menor ou igual que <b> {{`${this.formatPrice(valorMaxSaque)}`}} </b>.
            .p-col-12.ta-right
                    Button(label='Salvar' :disabled="(!model.tipoOperacao || !model.nr_valor)" type="submit" @click='dialogConfirm=true')
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import { Caixas } from "@/middleware";

    import { required, minValue, maxValue } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column,
        },
        props: [
            'display',
            'cdCaixa',
            'valorMaxSaque',
            'valorMaxDeposito',
        ],
        computed: {
            show: {
                get() { 
                    if (!this.display) this.resetModel()
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
            valorMax: {
                get(){
                    if ( this.model.tipoOperacao == "D" ) return this.valorMaxDeposito
                    return this.valorMaxSaque
                }
            }
        },
        created () {

        },
        data () {
            return {
                model: {
                    nr_valor: null,
                    tipoOperacao: null,
                },
                options: {
                    tipoOperacaoOption: [
                        {
                            label: "Depósito",
                            value: "D",
                        },
                        {
                            label: "Saque",
                            value: "S",
                        }
                    ]
                },
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    nr_valor: {
                        required,
                        minValue: minValue(0.01),
                        maxValue: maxValue(this.valorMax)
                    },
                    tipoOperacao: { required },
                }
            }
            
            return v
        },
        methods: {
            resetModel() {
                this.model.nr_valor = null
                this.model.tipoOperacao = null
                this.waiting = true,
                this.waitingForm = false,
                this.submitted = false
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            setValorMax () {
                if ( this.model.tipoOperacao == "D" ) this.model.nr_valor = this.valorMaxDeposito
                else if ( this.model.tipoOperacao == "S" ) this.model.nr_valor = this.valorMaxSaque
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let nr_valor_f
                if(this.model.tipoOperacao == "D") {
                    nr_valor_f = this.model.nr_valor
                }
                else if(this.model.tipoOperacao == "S") {
                    nr_valor_f = this.model.nr_valor * -1
                }

                Caixas.atualizarFundoFixo({cd_caixa: this.cdCaixa, nr_valor: nr_valor_f}).then(response => {
                    this.submitted = false;
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success(
                            `${this.model.tipoOperacao === "D" ? "Deposito" : "Saque"} REALIZADO com sucesso.`
                        );
                        this.$emit('close')
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                });
            }
        },
    }
</script>

<style lang="scss">

.dialog-sangria {
    width: 600px;
    .p-dialog-content {
        padding-bottom: 6px;
    }
    .confirm-button {
        background-color: green;
    }
    .cancel-button {
        background-color: red;
    }
    .p-button.p-button-text {
        color: white;
        border-color: transparent;

        &:hover {
            background-color: transparent;
            color: black;
            border-color: black;
        }
    }
}
</style>
