<template lang="pug">
Dialog.dialogFechar(header='Fechar o caixa' :visible.sync='show' :modal='true' @hide='onHideDialog()')
    ImprimirRelatorio(:display='dialogImprimir' :tipo_caixa="tipoCaixa" :id='caixaFechadoId' @close='dialogImprimir=false')
    .ta-center(v-if='waitingResumoCaixa')
        ProgressSpinner.spinner-resumoCaixa(strokeWidth='2')
    div(v-else-if='resumoCaixa')
        h1.ta-center Resumo do caixa{{ saldoCaixa ? `: ${resumoCaixa.nm_caixa}` : ' atual'}}
        p <b>Caixa:</b> {{ resumoCaixa.nm_caixa }}
        span(v-if='resumoCaixa.nm_sala') <b>Sala:</b> {{ resumoCaixa.nm_sala }}
        p(v-if='resumoCaixa.saldo_abertura') <b>Saldo de abertura:</b> {{ formatPrice(resumoCaixa.saldo_abertura) }}
        template(v-for="(forma_pagamento, idx) in resumoCaixa.formas_pagamento")
            p(:key="idx") <b>Pagamento em {{ forma_pagamento.nm_forma_pagamento }}:</b> {{ formatPrice(forma_pagamento.nr_valor) }}
        .p-grid.p-fluid
            //- .p-col-12.mt-4(:class="{ 'form-group--error': submitted && $v.model.cd_comprovante.$error }")
            //-     label.form-label Código do Comprovante:
            //-     InputText(v-model='$v.model.cd_comprovante.$model' type='number' @input='limitarCaracteres()')
            //-     .feedback--errors(v-if='submitted && $v.model.cd_comprovante.$error')
            //-         .form-message--error(v-if="!$v.model.cd_comprovante.tipoUser") <b>Código do Comprovante</b> é obrigatório.
            .p-col-12.mt-4
                label.form-label Código do Comprovante:
                InputText(v-model='model.cd_comprovante' type='number' @input='limitarCaracteres()')
        .ta-center.my-4
            ProgressSpinner.spinner-fecharCaixa(v-if='waitingFecharCaixa' strokeWidth='4')
            Button.p-button-success.btn-fecharCaixa(v-else-if='caixaFechadoId' label='Imprimir relatório' icon='jam jam-printer' @click='imprimirRelatorio()')
            Button.p-button-warning.btn-fecharCaixa(v-else label='Encerrar' icon='jam jam-power' @click='fecharCaixa()')
</template>

<style lang="scss">
    .dialogFechar {
        width: 95%; max-width: 500px;
        .p-dialog-content {
            h1 {
                font-size: 16px;
            }
            p {
                margin: 4px 0;
            }
        }
        .btn-fecharCaixa {
            font-weight: 700;
        }
        .spinner-resumoCaixa {
            height: 100px;
            display: inline-block;
            margin: 40px auto;
        }
        .spinner-fecharCaixa {
            width: 27px;
            height: auto;
        }
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressSpinner from 'primevue/progressspinner'
    import Button from 'primevue/button'
	import { Caixas } from './../../middleware'
    import ImprimirRelatorio from './../Caixa/ImprimirRelatorio'
    import InputText from 'primevue/inputtext'
    
    export default {
        components: { Dialog, ProgressSpinner, Button, ImprimirRelatorio, InputText },
        props: [ 'saldoCaixa', 'visible', 'supervisao', 'operacional' ],
        computed: {
            show: {
                get() {
                    if (this.visible) this.getResumoCaixa();
                    return this.visible;
                },
                set(val) { if (!val) this.$emit('close'); }
            }
        },
        data () {
            return {
                model: {
                    cd_comprovante: null,
                },
				waitingResumoCaixa: false,
				waitingFecharCaixa: false,
                resumoCaixa: null,
                caixaFechadoId: null,
                dialogImprimir: false,
                rotaCaixa: null,
                tipoCaixa: null,
                submitted: false,
            }
        },
        validations () {
            let v = {
                model: {
                    // cd_comprovante: { 
                    //     tipoUser(value) {
                    //         if (!(this.supervisao || !this.operacional) && !value) {
                    //             return false
                    //         }
                    //         return true
                    //     }
                    // },
                }
            }
            
            return v
        },
        methods: {
            limitarCaracteres() {
                const maxLength = 10;
                if (this.model.cd_comprovante.length > maxLength) {
                    this.model.cd_comprovante = this.model.cd_comprovante.slice(0, maxLength);
                }
            },
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
            getResumoCaixa () {
                this.waitingResumoCaixa = true
                Caixas.getResumoCaixa(this.saldoCaixa).then(response => {
                    this.waitingResumoCaixa = false
                    if (response.status == 200) {
                        this.resumoCaixa = response.data
                    } else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                })
            },
            verificarRota() {
				let rotaAtual = this.$route.path.split('/').filter(segment => segment !== '')[1]
				this.rotaCaixa = rotaAtual != "visualizar-supervisao" 
                if (this.rotaCaixa) this.tipoCaixa = "OPE"
                else this.tipoCaixa = "SUP"
			},
            fecharCaixa () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let aux = {id: this.saldoCaixa}
                if(this.model.cd_comprovante) aux['cd_comprovante'] = this.model.cd_comprovante

                let auxCodigo = {}
                if(this.model.cd_comprovante) auxCodigo['cd_comprovante'] = this.model.cd_comprovante

                this.waitingFecharCaixa = true

                const aux2 = this.supervisao 
                                ? Caixas.fecharCaixaSupervisao(aux)
                                : this.operacional
                                    // ? Caixas.fecharCaixaOperacional({cd_comprovante: this.model.cd_comprovante})
                                    ? Caixas.fecharCaixaOperacional(auxCodigo)
                                    : Caixas.fecharCaixaOperacional(aux)
                 
                aux2.then(response => {
                    if (response.status == 200) this.caixaFechadoId = response.data.cd_saldo
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                    this.waitingFecharCaixa = false
                })
            },
            onHideDialog () {
                if (this.caixaFechadoId){
                    if(this.$route.query.inativo)
                        this.$router.go(-1)
                this.$router.go(0)
                }
                
            },
            imprimirRelatorio () {
                this.waitingFecharCaixa = true
                this.verificarRota()
                this.dialogImprimir = true
                this.waitingFecharCaixa = false
            }
        }
    }
</script>
