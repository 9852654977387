<template lang="pug">
.historico-visualizar
    ImprimirRelatorio(:display='dialogImprimir' :tipo_caixa="'FUN'" :id='cdFundoFixo' @close='dialogImprimir=false')
    ConfirmDialog(:display='dialogConfirm.visible' :text='dialogConfirm.msg' @close='dialogConfirm.visible=false' @confirmed='removerComprovante()')
    DepositoSaqueFundoFixo(:display='dialogDepositoSaque' :valorMaxSaque='model.saldo_atual' :valorMaxDeposito='saldoSupervisao' :cdCaixa='cdSupervisao' @close='dialogDepositoSaque=false')
    Dialog.dialogDetalhesResgate(:modal="true" header= "Fundo fixo" :visible.sync="show")
        .p-grid.p-fluid
            .p-col-12(style="align-self: flex-end; max-width: 720px;")
                div.mb-4(v-if='!waiting')
                    .mt-2.ta-right(v-if='model')
                        .ml-2(v-if='caixaAberto' style='display:inline-block')
                            Button.p-button-success(label="Depósito/Saque" icon="jam jam-coin" @click="dialogDepositoSaque = true")
                        .ml-2(style='display:inline-block')
                            Button(label='Imprimir relatório' icon="jam jam-printer" @click="dialogImprimir=true")
                ProgressBar(v-if="waiting" mode="indeterminate")
                .__box-imprimir(v-else-if='model' style='margin: 0 auto; max-width: 720px; align-self: flex-end;')
                    fieldset
                        legend.text-title Informações
                        .p-grid.p-align-end.mb-3.mt-2.ml-1
                            .p-col-6
                                p <b>Caixa:</b> {{ model.nm_caixa }}
                                p <b>Usuário de abertura:</b> {{ model.nm_usuario_abertura }}
                                p <b>Usuário de fechamento:</b> {{ model.nm_usuario_fechamento }}
                            .p-col-6
                                p <b>Data de abertura:</b> {{ model.dt_abertura_f }}
                                p <b>Data de fechamento:</b> {{ model.dt_fechamento_f }}
                    fieldset
                        legend.text-title Resumo
                        .p-grid.p-align-top.mb-3.mt-2.ml-1
                            .p-col-6
                                p(v-for="item in model.resumo1" )
                                    <b>{{ item.descricao }}:</b> <span :class="{'highlight': item.destacar}">{{ formatPrice(item.valor) }}</span>
                            .p-col-6
                                p(v-for="item in model.resumo2" )
                                    <b>{{ item.descricao }}:</b> <span :class="{'highlight': item.destacar}">{{ formatPrice(item.valor) }}</span>
                    fieldset(v-if="model.transferencias.length")
                        legend.text-title Transferências
                        DataTable.datatable-list(:value='model.transferencias')
                            //- Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='cd_saldo_origem' header='Origem' sortable)
                            //- Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='nm_caixa' header='Destino' sortable)
                            Column(headerStyle='width: 30%;' bodyStyle='text-align: center' header='Tipo' sortable)
                                template(#body='props')
                                    span {{ options.ie_tipo_movimento[props.data.ie_tipo_movimento] }}
                            Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
                            Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor' sortable)
                                template(#body='props')
                                    span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
                            ColumnGroup(type="footer")
                                Row
                                    Column(footer="Total:" :colspan="2" footerStyle="text-align: right")
                                    Column(:footer="formatPrice(model.total_transferencias)" footerStyle="text-align: right")

                    fieldset(v-if="model.movimentos.length")
                        legend.text-title Movimentos
                        DataTable.datatable-list(:value='model.movimentos')
                            Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='nm_caixa' header='Caixa' sortable)
                            Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
                            Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor' sortable)
                                template(#body='props')
                                    span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
                            ColumnGroup(type="footer")
                                Row
                                    Column(footer="Total:" :colspan="2" footerStyle="text-align: right")
                                    Column(:footer="formatPrice(model.total_movimentos)" footerStyle="text-align: right")
</template>

<script>
    import Dialog from 'primevue/dialog';
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Calendar from 'primevue/calendar'
    import DataTable from 'primevue/datatable'
    import Column from 'primevue/column'
    import ColumnGroup from 'primevue/columngroup'
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
    import { Caixas } from "@/middleware";
    import moment from 'moment'
    import Row from 'primevue/row'
    import DepositoSaqueFundoFixo from './../Caixa/DepositoSaqueFundoFixo'
    import { DominioValor } from './../../middleware'
    import ImprimirRelatorio from './../Caixa/ImprimirRelatorio'

    // import { required, minValue } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password, Dialog, InputMask, SelectButton, Dropdown, Calendar, DataTable, Column, ConfirmDialog,
            ColumnGroup, Row, DepositoSaqueFundoFixo, ImprimirRelatorio,
        },
        props: [
            'display', 'cdFundoFixo', 'saldoSupervisao', 'cdSupervisao'
        ],
        computed: {
            show: {
                get() { 
                    if(this.display){
                        this.getFundoFixo()
                        this.getDominio()
                    }
                    return this.display; 
                },
                set(value) { if (!value) this.$emit('close'); }
            },
        },
        data () {
            return {
                model: {
					resumo: [],
					movimentos: [],
					transferencias: [],
				},
				id: null,
				caixaAberto: true,
                options: {
					ie_tipo_movimento: {}
				},
                ptbr: pCalendarLocale_ptbr,
                waiting: true,
                waitingComprovante: false,
                waitingForm: false,
                dialogConfirm: {
                    visible: false,
                    msg: '',
                },
                dialogDepositoSaque: false,
                dialogImprimir: false,
            }
        },
        validations () {
            let v = {
                model: {

                }
            }
            
            return v
        },
        methods: {
            getDominio(){ 
				DominioValor.findAll({ds_mnemonico: 'TIPO_MOVIMENTO_CAIXA'}).then((response) => {
					if(response.status === 200) {
						response.data['TIPO_MOVIMENTO_CAIXA'].valores.forEach(item => {
							this.options.ie_tipo_movimento[item.ie_valor] = item.ds_valor
						})
					}
				})
			},
            getFundoFixo () {
                let usuario = JSON.parse(localStorage.getItem('usuario'))
                this.usuarioNivel = usuario.nivel

                this.waiting = true
                Caixas.findFechamentoFundoFixo(this.cdFundoFixo).then(response => {
                    if (response.status === 200) {
                        this.model = response.data
                        this.model.resumo1 = this.model.resumo.slice(0, Math.ceil( this.model.resumo.length/2 ))
                        this.model.resumo2 = this.model.resumo.slice( Math.ceil( this.model.resumo.length/2 ))
                        this.model.dt_abertura_f = moment(this.model.dt_abertura).format('DD/MM/YYYY HH:mm')
                        if (this.model.dt_fechamento) {
                            this.model.dt_fechamento_f = moment(this.model.dt_fechamento).format('DD/MM/YYYY HH:mm')
                            this.caixaAberto = false
                        }
                        this.model.movimentos.forEach(item => {
                            item.dt_criado_f = moment(item.dt_criado).format('DD/MM/YYYY HH:mm:ss')
                            item.dt_movimento_f = moment(item.dt_movimento).format('DD/MM/YYYY')
                            item.nr_valor_f = this.formatPrice(item.nr_valor)
                        })
                        this.model.transferencias.forEach(item => {
                            item.dt_criado_f = moment(item.dt_criado).format('DD/MM/YYYY HH:mm')
                        })
                    }
                    this.waiting = false
                })
            },
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            openConfirmRemoverComprovante() {
                this.dialogConfirm.msg = `Deseja mesmo REMOVER o comprovante anexado?`;
                this.dialogConfirm.visible = true;
            },
         }
      }
</script>

<style lang="scss">
    .dialogDetalhesResgate {
        width: 98%;
        max-width: 750px;
    }
	.historico-visualizar {
		.spinner {
			margin: 100px 0;
		}
		.text-footer {
			font-size: 12px;
			margin-top: -10px !important;
		}
		.img-logo {
			max-width: 160px;
			@media screen {
				display: none;
			}
		}
		.__box-imprimir {
			border: 1px solid #c8c8c8;
			padding: 20px;
			fieldset {
				border: 1px solid #c8c8c8;
			}
			p {
				margin: 2px 0;
				font-size: 12px;
				color: #333;
			}
			.text-title {
				color: #657786;
				font-size: 14px;
				font-weight: 700;
			}
			.table {
				border-bottom: 1px solid #c8c8c8;
				.row {
					font-size: 0;
					border-top: 1px solid #c8c8c8;
					border-left: 1px solid #c8c8c8;
					.col {
						display: inline-block;
						vertical-align: middle;
						font-size: 12px;
						border-right: 1px solid #c8c8c8;
						padding: 2px 6px;
					}
				}
			}
			.p-progress-spinner {
				height: 29px;
				width: 29px;
			}
			@media print {
				.box-hide { display: none }
			}
			.datatable-list {
				.p-column-title, td {
					font-size: 12px;
				}
			}
			.highlight {
				color: #007ad9;
				font-weight: 700;
			}
		}
	}
</style>