<template lang="pug">
.main-wrapper.historico-visualizar
	header.main-wrapper-header
		.p-grid.p-align-center
			.p-col-3
				Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
			.p-col-9.ta-right
				h1.text-header.text-secondary MedClub /
					<router-link :to='{ name: "fechamento-caixa" }'> Histórico de Fechamento</router-link> /
					<b>Visualizar</b>

	ImprimirRelatorio(:display='dialogImprimir' :tipo_caixa="'SUP'" :id='id' @close='dialogImprimir=false')
	FecharCaixa(v-if='model' :saldoCaixa='id' :visible="dialogFechar" :supervisao='true' @close="dialogFechar = false")
	ConfirmDialog(:display='dialogReabrir' @close='dialogReabrir=false' @confirmed='reabrirCaixa()')
		template(#text-content="props")
			//- Message(severity="warn" :sticky='true' :closable='false') Ao reabrir o caixa, esse caixa também será reaberto em <b>todos seus dias posteriores.</b>
			span Você está prestes a reabrir um caixa já fechado anteriormente. Deseja prosseguir com essa ação?
	VisualizarFundoFixo(:display='dialogFundoFixo' :saldoSupervisao='model.saldo_atual' :cdFundoFixo='model.cd_saldo_caixa_troco' :cdSupervisao='model.cd_caixa' @close='dialogFundoFixo=false')
	DepositoBanco(:display='dialogDeposito' :valorMax='model.saldo_atual' :dt_transferencia='model.dt_abertura' :cd_caixa='model.cd_caixa' @close='dialogDeposito=false')
	Panel.my-2(header='Histórico de Fechamento' style="vertical-align='center'")
		div.mb-4(v-if='!waiting')
			.mt-4.ta-right(v-if='model')
				.ml-2(style='display:inline-block')
					Button(label="Ver caixas operacionais do dia" icon="jam jam-screen" @click="verCaixasOperacionais()")
				.ml-2(v-if='caixaAberto' style='display:inline-block')
					Button.p-button-success(label="Depositar Banco" icon="jam jam-share" :disabled="model.saldo_atual <= 0" @click="dialogDeposito = true")
				.ml-2(style='display:inline-block')
					Button.p-button-secondary(:label="`Fundo Fixo: ${this.formatPrice(model.saldo_troco)}`" icon="jam jam-inbox" @click="dialogFundoFixo = true")
				.ml-2(style='display:inline-block' v-if='caixaAberto')
					Button.p-button-warning(label='Fechar caixa' icon="jam jam-power" @click="dialogFechar = true")
				.ml-2(style='display:inline-block' v-if='caixaAberto == false')
					Button(label='Reabrir caixa' icon="jam jam-screen" @click="dialogReabrir = true")
				.ml-2(style='display:inline-block')
					Button(label='Imprimir relatório' icon="jam jam-printer" @click="dialogImprimir=true")
		.ta-center(v-if='waiting')
			ProgressSpinner.spinner(strokeWidth="2")
		.__box-imprimir(v-else-if='model' style='margin: 0 auto; max-width: 720px;')
			fieldset
				legend.text-title Informações
				.p-grid.p-align-center.mb-3.mt-2.ml-1
					.p-col-6
						p <b>Caixa:</b> {{ model.nm_caixa }}
						p <b>Unidades:</b> 
							span {{ model.salas_f }}
					.p-col-6
						p <b>Usuário de abertura:</b> {{ model.nm_usuario_abertura }}
						p <b>Data de abertura:</b> {{ model.dt_abertura_f }}
						p <b>Usuário de fechamento:</b> {{ model.nm_usuario_fechamento }}
						p <b>Data de fechamento:</b> {{ model.dt_fechamento_f }}
			fieldset
				legend.text-title Resumo
				.p-grid.p-align-top.mb-3.mt-2.ml-1
					.p-col-6
						p(v-for="item in model.resumo1" )
							<b>{{ item.descricao }}:</b> <span :class="{'highlight': item.destacar}">{{ formatPrice(item.valor) }}</span>
					.p-col-6
						p(v-for="item in model.resumo2" )
							<b>{{ item.descricao }}:</b> <span :class="{'highlight': item.destacar}">{{ formatPrice(item.valor) }}</span>

			fieldset(v-if="model.sangrias.length")
				legend.text-title Sangrias
				DataTable.datatable-list(:value='model.sangrias')
					Column(headerStyle='width: 20%;' bodyStyle='text-align: right' header='Ver caixa')
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Ver caixa'"
									icon="pi pi-external-link"
									@click="verCaixaOperacional(props.data.cd_saldo_origem)"
								)
					Column(headerStyle='width: 35%;' bodyStyle='text-align: center' field='nm_caixa' header='Caixa' sortable)
						template(#body='props')
							span {{ props.data.nm_caixa }} <br>
							em(v-if='props.data.nm_sala_origem' style='font-size: 10px') Unidade: {{ props.data.nm_unidade_origem }} <br> Setor: {{ props.data.nm_setor_origem }} <br> Sala: {{ props.data.nm_sala_origem }} <br>
					Column(headerStyle='width: 20%;' bodyStyle='text-align: right' field='nr_valor' header='Valor' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="2" :footerStyle="{'text-align':'right'}")
							Column(:footer="formatPrice(model.total_sangrias)" :footerStyle="{'text-align':'right'}")

			fieldset(v-if="model.transferencias.length")
				legend.text-title Transferências
				DataTable.datatable-list(:value='model.transferencias')
					//- Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='cd_saldo_origem' header='Origem' sortable)
					//- Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='nm_caixa' header='Destino' sortable)
					Column(headerStyle='width: 30%;' bodyStyle='text-align: center' header='Tipo' sortable)
						template(#body='props')
							span {{ options.ie_tipo_movimento[props.data.ie_tipo_movimento] }}
					Column(headerStyle='width: 30%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
					Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="2" footerStyle="text-align: right")
							Column(:footer="formatPrice(model.total_transferencias)" footerStyle="text-align: right")

			fieldset(v-if="model.estornos.length")
				legend.text-title Estornos
				DataTable.datatable-list(:value='model.estornos')
					Column(headerStyle='width: 20%;' bodyStyle='text-align: center' field='cd_transacao_financeira' header='Transação' sortable)
					Column(headerStyle='width: 27%;' bodyStyle='text-align: center' field='dt_criado_f' header='Data / Horário' sortable)
					Column(headerStyle='width: 25%;' bodyStyle='text-align: center' field='forma_pagamento' header='Forma de Pagamento' sortable)
					Column(headerStyle='width: 16%;' bodyStyle='text-align: right' header='Valor' field='nr_valor_f' sortable)
						template(#body='props')
							span(:class="{ 'valor-negativo': props.data.nr_valor < 0 }") {{ formatPrice(props.data.nr_valor) }}
					ColumnGroup(type="footer")
						Row
							Column(footer="Total:" :colspan="3" footerStyle="text-align: right")
							Column(:footer="formatPrice(model.total_estornos)" footerStyle="text-align: right")
</template>

<style lang="scss">
	.historico-visualizar {
		.spinner {
			margin: 100px 0;
		}
		.text-footer {
			font-size: 12px;
			margin-top: -10px !important;
		}
		.img-logo {
			max-width: 160px;
			@media screen {
				display: none;
			}
		}
		.__box-imprimir {
			border: 1px solid #c8c8c8;
			padding: 20px;
			fieldset {
				border: 1px solid #c8c8c8;
			}
			p {
				margin: 2px 0;
				font-size: 12px;
				color: #333;
			}
			.text-title {
				color: #657786;
				font-size: 14px;
				font-weight: 700;
			}
			.table {
				border-bottom: 1px solid #c8c8c8;
				.row {
					font-size: 0;
					border-top: 1px solid #c8c8c8;
					border-left: 1px solid #c8c8c8;
					.col {
						display: inline-block;
						vertical-align: middle;
						font-size: 12px;
						border-right: 1px solid #c8c8c8;
						padding: 2px 6px;
					}
				}
			}
			.p-progress-spinner {
				height: 29px;
				width: 29px;
			}
			@media print {
				.box-hide { display: none }
			}
			.datatable-list {
				.p-column-title, td {
					font-size: 12px;
				}
			}
			.highlight {
				color: #007ad9;
				font-weight: 700;
			}
		}
	}
</style>

<script>
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import ProgressSpinner from 'primevue/progressspinner'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import ColumnGroup from 'primevue/columngroup'
	import Row from 'primevue/row'
	import Tooltip from 'primevue/tooltip'

	import { Caixas } from '../../middleware'
	import FecharCaixa from './../Caixa/FecharCaixa'
	import RealizarSangria from './../Caixa/RealizarSangria'
	import DepositoBanco from './../Caixa/DepositoBanco'
	import Message from 'primevue/message'
	import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'
	import VisualizarFundoFixo from './VisualizarFundoFixo'
	import ImprimirRelatorio from './../Caixa/ImprimirRelatorio'
	import { DominioValor } from './../../middleware'

	import moment from 'moment'
	export default {
		data () {
			return {
				model: {
					transferencias: [],
					resumo: [],
					estornos: [],
					sangrias: [],
				},
				options: {
					ie_tipo_movimento: {}
				},
				id: null,
				waiting: false,
				dialogFechar: false,
				dialogReabrir: false,
				dialogFundoFixo: false,
				dialogDeposito: false,
				dialogImprimir: false,
				caixaAberto: true,
			}
		},
		components: {
			Panel, Button, ProgressSpinner, FecharCaixa, DataTable, Column, ColumnGroup, Row, RealizarSangria, DepositoBanco, Message, ConfirmDialog,
			VisualizarFundoFixo, Tooltip, ImprimirRelatorio,
		},
		directives: { tooltip: Tooltip },
		methods: {
			getDominio(){ 
				DominioValor.findAll({ds_mnemonico: 'TIPO_MOVIMENTO_CAIXA'}).then((response) => {
					if(response.status === 200) {
						response.data['TIPO_MOVIMENTO_CAIXA'].valores.forEach(item => {
							this.options.ie_tipo_movimento[item.ie_valor] = item.ds_valor
						})
					}
				})
			},
			verCaixasOperacionais() {
				const params = {
					dt_saldo_i: moment(this.model.dt_abertura).utcOffset('-03:00').format('DD/MM/YYYY'),
					cd_salas_list: this.model.salas.map(u => u.cd_sala),
					ie_tipo_caixa: "O"
				}
				this.$store.commit('setFilters', params)
				this.$router.push(`/fechamento-caixa/listar/`)
			},
			verCaixaOperacional(id){
				this.$router.push(`/fechamento-caixa/visualizar/${ id }/`)
			},
			reabrirCaixa(){
				if (isNaN(this.$route.params.id)) this.$router.go(-1)
				let saldoCaixa = parseInt(this.$route.params.id)

				Caixas.reabrirCaixa(saldoCaixa).then(response => {
					if (response.status === 200) {
                        this.$toast.success(
                            "Caixa REABERTO com sucesso."
                        );
                        location.reload()
                    }
                    else if (response.status === 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 });
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        } else if (response.data.non_field_errors) {
                            response.data.non_field_errors.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                        }
                    }
                })
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
		},
		created () {
			let usuario = JSON.parse(localStorage.getItem('usuario'))
			this.usuarioNivel = usuario.nivel

			if (isNaN(this.$route.params.id)) this.$router.go(-1)
			this.id = parseInt(this.$route.params.id)

			this.getDominio()

			this.waiting = true
			Caixas.findFechamentoSupervisao(this.id).then(response => {
				if (response.status === 200) {
					this.model = response.data
					this.model.resumo1 = this.model.resumo.slice(0, Math.ceil( this.model.resumo.length/2 ))
					this.model.resumo2 = this.model.resumo.slice( Math.ceil( this.model.resumo.length/2 ))
					this.model.dt_abertura_f = moment(this.model.dt_abertura).format('DD/MM/YYYY HH:mm')
					if (this.model.dt_fechamento) {
						this.model.dt_fechamento_f = moment(this.model.dt_fechamento).format('DD/MM/YYYY HH:mm')
						this.caixaAberto = false
					}
					this.model.estornos.forEach(item => {
						item.dt_criado_f = moment(this.model.dt_criado).format('DD/MM/YYYY HH:mm')
					})
					this.model.transferencias.forEach(item => {
						item.dt_criado_f = moment(item.dt_criado).format('DD/MM/YYYY HH:mm')
					})
					this.model.salas_f = []
					this.model.salas.forEach((u) => {
						if(!this.model.salas_f.includes(u.nm_unidade)) this.model.salas_f.push(u.nm_unidade)
					})
					this.model.salas_f = this.model.salas_f.join(', ')
					for (let i = this.model.sangrias.length - 1; i >= 0; i--) { // Função para remover sangrias que não possuem id
						let item = this.model.sangrias[i]
						if (!item.id) {
							this.model.sangrias.splice(i, 1)
							continue
						}
						this.model.sangrias[i].dt_criado_f = moment(item.dt_criado).format('DD/MM/YYYY HH:mm:ss')
					}
				}
				this.waiting = false
			})
		}
	}
</script>
